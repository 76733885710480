import { formatMoney } from '@utils/money';

import { InfoPopupMainItem, InfoPopupDropdownItem } from './InfoPopup.configs.types';

export const getMainTexts = (portfolioName: string): InfoPopupMainItem[] => [
  `This is your ‘${portfolioName}’ portfolio. Mocked now  waiting for backend's implementation`,
  `Your portfolio comprises specific exchange traded funds (“ETFs”) securities with generally low-costs and 
   high liquidity.`,
  `We execute portfolio rebalancing and tax-loss harvesting for you, to ensure giving you the best investment 
   experience and returns.`,
];

export const getDropdownTexts = (annualFee: number, minimalAmount: number): InfoPopupDropdownItem[] => [
  {
    key: 2,
    title: 'What are the costs?',
    text: `We charge ${formatMoney(annualFee)}% of your portfolio value per year. This means if your portfolio value 
    is $1,000, you pay ${formatMoney((1000 * annualFee) / 100)} per year.`,
  },
  {
    key: 3,
    title: 'Is my portfolio under active management?',
    text: `Yes, as part of our service, your portfolio undergoes automatic rebalancing to align with your risk 
    tolerance. Furthermore, we implement tax-loss harvesting to optimize performance, thereby minimizing tax 
    payments and enhancing your net returns.`,
  },
  {
    key: 4,
    title: 'What is the minimum investment amount?',
    text: `You can start investing from as little as ${formatMoney(minimalAmount)}. There is no investment maximum.`,
  },
  {
    key: 5,
    title: 'Why does my portfolio consist out of ETFs?',
    text: `Rather than selecting a single ETF, our approach involves investing in a subset of the S&P 500 that closely 
    replicates its performance. This decision is rooted in the desire to reduce the fees associated with ETFs. 
    Additionally, the incorporation of individual stocks and bonds enhances the efficacy of tax-loss harvesting, 
    leaving you with higher net returns at the end of the year.`,
  },
];
