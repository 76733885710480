import styled from 'styled-components';

import { gaps } from '~/ui/kit/constants/sizes';
import { textEllipsis } from '~/ui/kit/constants/textEllipsis';
import { media } from '~/ui/utils/media';

import { InfoControlFeature } from './features/InfoControl';
import { InfoPopupWidget } from './widgets/InfoPopup';

export const ContentContainer = styled.div`
  ${media.md`
    max-width: 750px;
  `}
`;

export const Header = styled(ContentContainer)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const PortfolioTitle = styled.div`
  display: flex;
  gap: ${gaps.xxs}px;
  max-width: calc(100% - 32px);
`;
export const PortfolioNameContainer = styled.div`
  color: var(--eko-third-color);
  display: none;
  overflow: hidden;
  margin-right: ${gaps.xxs}px;

  &:before {
    content: '(';
  }
  &:after {
    content: ')';
  }

  ${media.md`
    display: flex;
  `};
`;

export const PortfolioName = styled.div`
  ${textEllipsis};
`;

export const InfoFeature = styled(InfoControlFeature)`
  border-radius: 50%;
  color: var(--eko-primary-color-light-3);
`;

export const InfoPopup = styled(InfoPopupWidget)``;

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: ${gaps.m}px;

  ${media.md`
    grid-template-columns: 1.75fr 2fr;
  `}
`;

export const StatsContainer = styled(GridContainer)`
  margin-bottom: ${gaps.xxl * 2}px;
`;
